import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';

import { InputAsyncAutocomplete, InputAutocomplete, InputRange } from 'components/UI';
import { IInputAutocompleteOption } from 'components/UI/InputAsyncAutocomplete';

import { useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getWorkTags } from 'store';

import styles from './DesktopSearch.module.scss';

import { SearchDataKeys, SearchDataType, songCategories } from './data';
import { IWorkTagsTypes } from 'types';

interface ISearchAutocompletesListProps {
  control: Control<SearchDataType>;
  disabledBpm?: boolean;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  className?: string;
  type?: 'create' | 'search';
  withBPM?: boolean;
}

const SearchAutocompletesList = ({
  control,
  disabledBpm,
  labelPlacement = 'start',
  className,
  type,
  withBPM = true,
}: ISearchAutocompletesListProps): JSX.Element => {
  const workTags = useMemoSelector(getWorkTags);

  const isForCreate = type === 'create';

  return (
    <div className={classNames(styles.autocompleteContainer, className)}>
      {songCategories.map(({ title, value, id }) => (
        <InputAutocomplete
          multiple={value !== SearchDataKeys.genre}
          disableClearable={value !== SearchDataKeys.genre}
          key={id}
          name={value as SearchDataKeys}
          label={title}
          data={workTags[value as IWorkTagsTypes]}
          control={control}
          labelPlacement={labelPlacement}
        />
      ))}
      <Controller
        control={control}
        name={SearchDataKeys.artists}
        render={({ field: { onChange, value } }) => (
          <InputAsyncAutocomplete
            multiple
            addCustomOption={isForCreate}
            label="Artist"
            labelPlacement={labelPlacement}
            value={value as IInputAutocompleteOption[]}
            onChange={(_, value) => onChange(value)}
            inputBaseClassName={styles.artistsInputBaseClassName}
            autocompleteClassName={styles.artistsAutocomplete}
            className={isForCreate ? styles.addTagsArtistsAutocompleteLabel : styles.searchArtistsAutocompleteLabel}
            onResolveSuggestions={(text) => Api.getArtists(text).catch(() => [])}
            disableClearable
            disableCloseOnSelect
          />
        )}
      />
      {withBPM && (
        <InputRange
          name={SearchDataKeys.bpm}
          label="BPM"
          singleValue={isForCreate}
          control={control}
          disabledBpm={disabledBpm}
          labelPlacement={labelPlacement}
        />
      )}
    </div>
  );
};

export default SearchAutocompletesList;
