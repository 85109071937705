import isEqual from 'react-fast-compare';
import { batch } from 'react-redux';
import moment from 'moment-timezone';

import { IContact, IContactGroup } from 'components/Contacts';
import {
  AudioInclusionTypes,
  defaultSearchData,
  IUISearchData,
  SearchDataType,
} from 'components/Popups/DesktopSearch/data';
import {
  closeDownloadPopup,
  downloadingData,
  preparingDownloadData,
} from 'components/Popups/DownloadPopup/DownloadPopup';
import { IReportFilter } from 'components/ReportForm';
import { getLabelViewAsObject, getStatusLabel, getSubStatusLabel } from 'components/ReportForm/utils';
import { IDateRangeValue } from 'components/UI/NewDateRange';
import { UserStatuses } from 'components/UI/StatusCard/data';
import { IPlaylistMedia } from 'containers/Sidebar/data';

import {
  dispatch,
  getActiveRoute,
  getSelectors,
  setActiveTrack,
  setAdminControls,
  setCurrentPlaylistId,
  setCurrentTracks,
  setDownloadPopupConfig,
  setError,
  setFavoritesData,
  setFavoritesList,
  setHoldRequest,
  setMainConfirmPopup,
  setRecentPlayedList,
  setUserProfile,
  updatePlaylistHash,
} from 'store';
import { IUserProfile, PlaylistHashActionTypes } from 'store/reducers/general/types';
import {
  DEFAULT_ERROR_CONFIG,
  DEFAULT_MAIN_CONFIRM_POPUP,
  DEFAULT_USER,
  getTitlesArray,
  immediatelyPlayAudio,
  MAX_NOTIFICATIONS_COUNT_WARNING,
  MAX_NOTIFICATIONS_REQUEST_COUNT,
  Paths,
  sendAnalyticsEvent,
} from 'utils';

import globalStyles from 'styles/modules/Global.module.scss';

import Api, {
  DownloadStatuses,
  IContactGroupResponseItem,
  IContactResponseItem,
  IDownloadPitchesSharedSongs,
  IDownloadPlaylist,
  IDownloadReport,
  IDownloadSongs,
  IRequestParams,
} from '.';
import { AnalyticsEventTypes, IAutocompleteOption } from 'types';

export const updateUser = async (params?: IRequestParams) => {
  const userProfile: IUserProfile = await Api.getUser(params);

  if (!userProfile?.user?.status) {
    dispatch(setUserProfile({ user: { ...DEFAULT_USER.user, status: UserStatuses.ERROR } }));
  }

  userProfile && dispatch(setUserProfile(userProfile));

  return userProfile;
};

export const putTeamsAndUpdateUser = async (checkedTeams: string[]) => {
  await Api.putTeams(checkedTeams, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
  return updateUser();
};

export const updateStatusTabValues = async () => {
  const response = await Api.getStatusCount();
  response && dispatch(setAdminControls({ statusTabValues: response }));

  return response;
};

export const updateFavoritesList = async () => {
  const newFavoritesList = await Api.getFavoritePlaylist();

  dispatch(setFavoritesList(newFavoritesList?.medias || []));

  dispatch(
    setFavoritesData({
      id: newFavoritesList?.id || '',
      updatedOnTime: newFavoritesList?.updatedOnTime,
      updatedByName: newFavoritesList?.updatedByName || '',
      createdOnTime: newFavoritesList?.createdOnTime,
      createdByName: newFavoritesList?.createdByName || '',
    })
  );

  return newFavoritesList;
};

export const updateRecentPlayedList = async () => {
  const activeRoute = getSelectors(getActiveRoute);

  if (activeRoute?.path !== Paths.dashboard) {
    return;
  }

  const newRecentPlayedList = await Api.getRecentPlayedMedias();

  newRecentPlayedList?.length && dispatch(setRecentPlayedList(newRecentPlayedList));
};

export const updateAllPlaylists = async (res: unknown) => {
  await updateRecentPlayedList();
  dispatch(updatePlaylistHash(PlaylistHashActionTypes.playlist));
  await updateFavoritesList();
  return res;
};

export const getQuickSearchBody = (fields: SearchDataType, removeBooleans?: boolean) => {
  const {
    searchQuery,
    genre,
    mood,
    vocal,
    language,
    artists,
    bpm,
    audioInclusionType,
    cut,
    offHold,
    onHold,
    unreleasedCut,
  } = fields;

  const genres = genre
    ? ([genre] as unknown as IUISearchData[]).map((item) => {
        return {
          name: item.title,
        };
      })
    : [];

  return {
    searchQuery,
    genres,
    ...(!removeBooleans && {
      audioInclusionType,
      onHold,
      cut,
      unreleasedCut,
      offHold,
    }),
    artists: getTitlesArray(artists as IUISearchData[]),
    moods: getTitlesArray(mood as IUISearchData[]),
    vocals: getTitlesArray(vocal as IUISearchData[]),
    languages: getTitlesArray(language as IUISearchData[]),
    ...(!isEqual(bpm, defaultSearchData.bpm) &&
      audioInclusionType !== AudioInclusionTypes.withoutAudio && {
        bpmRange: {
          start: (bpm as number[])[0],
          end: (bpm as number[])[1],
        },
      }),
  };
};

export const mapToContact = (item: IContactResponseItem): IContact => ({
  createdBy: item.createdByName,
  createdOn: item.createdOnTime ? new Date(item.createdOnTime) : undefined,
  email: item.email,
  id: item.id,
  name: item.name,
  organizationName: item.organizationName,
  phone: item.phone,
  role: item.role,
  team: item.teamName,
});

export const mapToGroup = (item: IContactGroupResponseItem): IContactGroup => ({
  createdBy: item.createdByName,
  createdOn: item.createdOnTime ? new Date(item.createdOnTime) : undefined,
  id: item.id,
  modifiedBy: item.updatedByName,
  title: item.name,
});

export const mapReportFiltersToRequest = (filter: IReportFilter): IReportFilter => {
  switch (filter.key) {
    case 'status':
    case 'subStatus':
      return {
        key: filter.key,
        value: (filter.value as IAutocompleteOption[] | undefined)?.map((v) => v.id.toString()),
      };
    case 'labelView':
      return {
        key: filter.key,
        value: (filter.value as IAutocompleteOption).id === 'YES',
      };
    case 'statusDate':
    case 'creationDate':
    case 'uploadDate':
      return {
        key: filter.key,
        value: {
          start: moment((filter.value as IDateRangeValue).start)
            .utcOffset(0)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate(),
          end: moment((filter.value as IDateRangeValue).end)
            .utcOffset(0)
            .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
            .toDate(),
        },
      };
  }
  return filter;
};

export const mapReportFiltersToItem = (filter: IReportFilter): IReportFilter => {
  const currentTimezone = moment.tz.guess();

  switch (filter.key) {
    case 'status':
      return {
        key: filter.key,
        value: (filter.value as string[] | undefined)?.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (v): IAutocompleteOption => ({ id: v, title: getStatusLabel(v as unknown as any) })
        ),
      };
    case 'subStatus':
      return {
        key: filter.key,
        value: (filter.value as string[] | undefined)?.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (v): IAutocompleteOption => ({ id: v, title: getSubStatusLabel(v as unknown as any) })
        ),
      };
    case 'labelView':
      return {
        key: filter.key,
        value: getLabelViewAsObject()[filter.value ? 'YES' : 'NO'],
      };
    case 'statusDate':
    case 'creationDate':
    case 'uploadDate':
      return {
        key: filter.key,
        value: {
          start: moment((filter.value as IDateRangeValue).start)
            .utc(false)
            .tz(currentTimezone, true)
            .toDate(),
          end: moment((filter.value as IDateRangeValue).end)
            .utc(false)
            .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
            .tz(currentTimezone, true)
            .toDate(),
        },
      };
  }

  return filter;
};

export const playPlaylistById = async (playlistId: string) => {
  const playlist = await Api.getPlaylistDetails({
    pathId: playlistId,
    errorPopupConfig: DEFAULT_ERROR_CONFIG,
  });

  if (!playlist) return;

  batch(() => {
    dispatch(setCurrentPlaylistId(playlistId));

    if (!playlist.medias.length) return;

    dispatch(
      setCurrentTracks(
        playlist.medias.map((item: IPlaylistMedia) => ({
          mediaId: item.id,
          id: item.songId,
          title: item.title,
          path: item.mediaPath,
          playbackPath: item.mediaPlaybackPath,
          writers: item.creators,
          version: item.version,
        }))
      )
    );
    dispatch(setActiveTrack({ index: 0 }));

    immediatelyPlayAudio();
  });
};

export const downloadAudio = async (mediaPath: string, title: string) => {
  const blob = await Api.getAudio({
    pathId: mediaPath,
    getWithBlob: true,
    withoutApi: true,
    errorPopupConfig: DEFAULT_ERROR_CONFIG,
  });

  const mime = mediaPath.split('.')[1];

  if (!blob) return;

  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = `${title}${mime ? `.${mime}` : ''}` || mediaPath;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  sendAnalyticsEvent(AnalyticsEventTypes.downloadSong);
};

export const downloadReport = async ({
  id,
  fileName = `${Date.now()}.csv`,
  shouldCallGenerate = true,
  params,
}: IDownloadReport) => {
  if (shouldCallGenerate) {
    const res = await Api.generateReport(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) return;
  }
  const { status } = (await Api.getGenerateReportStatus(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || {};

  if (status === DownloadStatuses.FAILED) {
    dispatch(setError(DEFAULT_ERROR_CONFIG));
    return;
  }

  if (status !== DownloadStatuses.FINISHED) {
    setTimeout(() => downloadReport({ id, params, shouldCallGenerate: false, fileName }), 1000);
    return;
  }

  const { url, name } = (await Api.getReportUrl(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || {};

  if (!url) return;

  const a = window.document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  a.remove();

  return url;
};

export const downloadPreSigned = async ({ fileName, url }: { fileName: string; url: string }) => {
  const a = window.document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadSongs = async ({ ids, generatedId, params }: IDownloadSongs) => {
  const timeoutTime = ids.length * 100 < 1000 ? 1000 : ids.length * 100;

  if (!generatedId) {
    dispatch(
      setDownloadPopupConfig({
        ...preparingDownloadData,
        statusText: `Zipping ${ids?.length || 0} files`,
      })
    );

    const res = await Api.generateSongsZip(ids, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    generatedId = res?.id;
  }

  if (!generatedId) {
    closeDownloadPopup();
    return;
  }

  const { status } =
    (await Api.getGenerateSongsZipStatus(generatedId, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || {};

  if (status === DownloadStatuses.FAILED) {
    dispatch(setError(DEFAULT_ERROR_CONFIG));
    closeDownloadPopup();
    return;
  }

  if (status !== DownloadStatuses.FINISHED) {
    setTimeout(() => downloadSongs({ ids, params, generatedId }), timeoutTime);
    return;
  }

  dispatch(
    setDownloadPopupConfig({
      ...downloadingData,
      statusText: `${ids?.length || 0} files zipped`,
    })
  );

  const { url } = (await Api.getSongsZipUrl({ errorPopupConfig: DEFAULT_ERROR_CONFIG, pathId: generatedId })) || {};

  if (!url) {
    closeDownloadPopup();
    return;
  }

  const a = window.document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  a.click();
  a.remove();

  closeDownloadPopup();
};

export const downloadPlaylist = async ({
  id,
  name = `${Date.now()}`,
  mediaCount = 1,
  shouldCallGenerate = true,
  params,
}: IDownloadPlaylist) => {
  const timeoutTime = mediaCount * 100 < 1000 ? 1000 : mediaCount * 100;

  const fileText = mediaCount === 1 ? 'file' : 'files';

  if (shouldCallGenerate) {
    dispatch(
      setDownloadPopupConfig({
        ...preparingDownloadData,
        statusText: `Zipping ${mediaCount} ${fileText}`,
      })
    );

    const res = await Api.generatePlaylistZip(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) {
      closeDownloadPopup();
      return;
    }
  }

  const { status } = (await Api.getGeneratePlaylistZipStatus(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || {};

  if (status === DownloadStatuses.FAILED) {
    dispatch(setError(DEFAULT_ERROR_CONFIG));
    closeDownloadPopup();
    return;
  }

  if (status !== DownloadStatuses.FINISHED) {
    setTimeout(() => downloadPlaylist({ id, name, params, shouldCallGenerate: false }), timeoutTime);
    return;
  }

  dispatch(
    setDownloadPopupConfig({
      ...downloadingData,
      statusText: `${mediaCount} ${fileText} zipped`,
    })
  );

  const { url } = (await Api.getPlaylistZipUrl(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || {};

  if (!url) {
    closeDownloadPopup();
    return;
  }

  const a = window.document.createElement('a');
  a.href = url;
  a.download = `${name}.zip`;
  document.body.appendChild(a);
  a.click();
  a.remove();

  closeDownloadPopup();
  sendAnalyticsEvent(AnalyticsEventTypes.downloadPlayList);
};

export const downloadPitchesSharedSongs = async ({
  code,
  type,
  name = `${Date.now()}`,
  mediaCount = 1,
  shouldCallGenerate = true,
  params,
}: IDownloadPitchesSharedSongs) => {
  const timeoutTime = mediaCount * 100 < 1000 ? 1000 : mediaCount * 100;

  const fileText = mediaCount === 1 ? 'file' : 'files';

  if (shouldCallGenerate) {
    dispatch(
      setDownloadPopupConfig({
        ...preparingDownloadData,
        statusText: `Zipping ${mediaCount} ${fileText}`,
      })
    );

    const res = await Api.generatePitchSharedSongsZip(type, code, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) {
      closeDownloadPopup();
      return;
    }
  }

  const { status } =
    (await Api.getGeneratePitchSharedSongsZipStatus(type, code, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || {};

  if (status === DownloadStatuses.FAILED) {
    dispatch(setError(DEFAULT_ERROR_CONFIG));
    closeDownloadPopup();
    return;
  }

  if (status !== DownloadStatuses.FINISHED) {
    setTimeout(() => downloadPitchesSharedSongs({ type, code, name, params, shouldCallGenerate: false }), timeoutTime);
    return;
  }

  dispatch(
    setDownloadPopupConfig({
      ...downloadingData,
      statusText: `${mediaCount} ${fileText} zipped`,
    })
  );

  const { url } = (await Api.getPitchSharedSongsZipUrl(type, code, { errorPopupConfig: DEFAULT_ERROR_CONFIG })) || {};

  if (!url) {
    closeDownloadPopup();
    return;
  }

  const a = window.document.createElement('a');
  a.href = url;
  a.download = `${name}.zip`;
  document.body.appendChild(a);
  a.click();
  a.remove();

  closeDownloadPopup();
};

export const updateHoldRequestStatusTabValues = async (updateTable?: boolean) => {
  const response = await Api.getHoldsStatusCount();
  response &&
    dispatch(
      setHoldRequest({ statusTabValues: response, ...(updateTable && { reloadHash: new Date().toISOString() }) })
    );

  return response;
};

export const handleNotificationsRequestCount = (
  notificationsRequestsCount: number,
  signOut: () => void,
  resetNotificationsRequestsCount: () => void
) => {
  if (notificationsRequestsCount === MAX_NOTIFICATIONS_COUNT_WARNING) {
    dispatch(
      setMainConfirmPopup({
        isOpen: true,
        questionText: 'Need More Time?',
        mainText: (
          <>
            Your session is about to expire. You will be automatically signed out in:{' '}
            <span className={globalStyles.f13h16SuisseSB_grayDark}>10 minutes</span>
          </>
        ),
        btnDoneText: 'Stay Signed In',
        btnCancelText: 'Sign Out',
        onClickCancel: signOut,
        onClickSubmit: () => {
          resetNotificationsRequestsCount();
          dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
        },
        withBackdrop: true,
      })
    );
  }

  if (notificationsRequestsCount === MAX_NOTIFICATIONS_REQUEST_COUNT) {
    dispatch(
      setMainConfirmPopup({
        isOpen: true,
        questionText: 'Your Session Has Expired Due to Inactivity',
        mainText: 'Please sign in to continue',
        btnDoneText: 'Sign In',
        hideButton1: true,
        onClickSubmit: signOut,
        withBackdrop: true,
      })
    );
  }
};
