import { defaultAdminControlsFilterData, defaultStatusTabValues } from 'components/AdminControls/data';
import { defaultPitchesModuleFilterData } from 'components/Pitches/data';
import { defaultSearchData, SearchItemsNums } from 'components/Popups/DesktopSearch/data';
import { defaultSongsModuleFilterData } from 'components/Songs/data';

import {
  DEFAULT_ACTIVE_NAV_ITEM_ID,
  DEFAULT_HOLD_REQUEST_CONFIG,
  DEFAULT_MAIN_CONFIRM_POPUP,
  DEFAULT_NOTIFICATIONS_CONFIG,
  DEFAULT_PITCH_DETAILS_DELETE_POPUP_CONFIG,
  DEFAULT_PLAYLIST_FILTER,
  DEFAULT_SHARE_CONFIG,
  DEFAULT_SONG_DETAILS_ADD_POPUP_CONFIG,
  DEFAULT_SONG_DETAILS_DELETE_POPUP_CONFIG,
  DEFAULT_USER,
  DEFAULT_WORK_TAGS,
  defaultPitchesConfirmPopupConfig,
  defaultPitchesExpirationConfig,
  defaultSongsModuleMyFilterData,
  getDefaultSearchItemsNum,
  INITIAL_DOWNLOAD_POPUP_CONFIG,
  INITIAL_ERROR_POPUP_CONFIG,
} from 'utils';

import IGeneralState, { PlayingStateTypes, PlaylistHashActionTypes } from './types';

const generalInitState: IGeneralState = {
  loading: false,
  isOpenSidebar: false,
  isOpenTopMenu: false,
  isOpenMobileMockup: false,
  isExpandedPlaybar: false,
  // Search
  selectedRowIndex: 0,
  isOpenSearch: false,
  isOpenShowHideColumns: false,
  isMarkersVisible: false,
  searchData: defaultSearchData,
  activeNavItemId: DEFAULT_ACTIVE_NAV_ITEM_ID,
  searchItemsNum: getDefaultSearchItemsNum ? getDefaultSearchItemsNum() : SearchItemsNums.seven,
  // My Account
  userProfile: DEFAULT_USER,
  // Admin Controls
  adminControls: {
    filtersData: defaultAdminControlsFilterData,
    isOpenEditPopup: false,
    isOpenFiltersPopup: false,
    openConfirmPopupType: '',
    activeEditingUser: null,
    statusTabValues: defaultStatusTabValues,
  },
  // Songs
  songsModule: {
    filtersData: defaultSongsModuleFilterData,
    myFilter: defaultSongsModuleMyFilterData,
    isOpenFiltersPopup: false,
    localAssets: [],
  },
  songDetails: {
    deletePopupConfig: DEFAULT_SONG_DETAILS_DELETE_POPUP_CONFIG,
    addPopupConfig: DEFAULT_SONG_DETAILS_ADD_POPUP_CONFIG,
  },
  recentEditedSongs: {
    songs: [],
    usages: [],
  },

  // Pitches
  pitchesModule: {
    filtersData: defaultPitchesModuleFilterData,
    isOpenFiltersPopup: false,
    expirationConfig: defaultPitchesExpirationConfig,
    confirmPopupConfig: defaultPitchesConfirmPopupConfig,
    myFilter: defaultSongsModuleMyFilterData,
    reloadHash: new Date().toISOString(),
  },
  pitchDetails: {
    deletePopupConfig: DEFAULT_PITCH_DETAILS_DELETE_POPUP_CONFIG,
  },
  // Playlist
  playlistHash: {
    action: PlaylistHashActionTypes.songDetails,
    hash: 0,
  },
  currentPlaylistId: '',
  favoritesList: [],
  favoritesData: {
    id: '',
    updatedByName: '',
    updatedOnTime: '',
    createdByName: '',
    createdOnTime: '',
  },
  favoritesIdsObj: {},
  recentPlayedList: [],
  playlistFilter: DEFAULT_PLAYLIST_FILTER,
  changedPlaylistId: '',
  editPlaylist: { id: '' },
  // Featured Playlist
  featuredPlaylistHash: 0,
  // Error
  error: INITIAL_ERROR_POPUP_CONFIG,
  // Download Popup
  downloadPopupConfig: INITIAL_DOWNLOAD_POPUP_CONFIG,
  // Playbar
  currentTracks: [],
  activeTrack: {
    mediaId: '',
    songId: '',
    index: 0,
  },
  playbarPlayingState: PlayingStateTypes.paused,
  // Hold Request
  holdRequest: DEFAULT_HOLD_REQUEST_CONFIG,
  shareConfig: DEFAULT_SHARE_CONFIG,
  mainConfirmPopup: DEFAULT_MAIN_CONFIRM_POPUP,
  mainNotification: '',
  notificationsConfig: DEFAULT_NOTIFICATIONS_CONFIG,
  // Tags
  workTags: DEFAULT_WORK_TAGS,
};

export default generalInitState;
