import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { SearchAutocompletesList } from 'components/Popups/DesktopSearch';
import { defaultSearchData, IUISearchData, SearchDataType } from 'components/Popups/DesktopSearch/data';

import Api, { IAddSongTagBody } from 'services/Api';

import styles from './SongDetails.module.scss';

import { IAddPopupProps } from 'types';

const AddTags = ({ addRef }: IAddPopupProps) => {
  const { id } = useParams<{ id: string }>();

  const { control, watch } = useForm<SearchDataType>({
    defaultValues: defaultSearchData,
  });

  const onHandleSubmit = () => {
    const { genre, mood, artists, language, vocal } = watch();

    const tags: IAddSongTagBody[] = [];

    artists && (artists as IUISearchData[]).forEach((a) => tags.push({ tag: a.title, type: 'artist' }));
    genre && tags.push({ tag: (genre as unknown as IUISearchData).title, type: 'genre' });
    language && (language as IUISearchData[]).forEach((l) => tags.push({ tag: l.title, type: 'language' }));
    mood && (mood as IUISearchData[]).forEach((m) => tags.push({ tag: m.title, type: 'mood' }));
    vocal && (vocal as IUISearchData[]).forEach((v) => tags.push({ tag: v.title, type: 'vocal' }));

    return Api.addSongTags(id, tags);
  };

  useImperativeHandle(addRef, () => ({ onHandleSubmit }));

  return (
    <div className={styles.addTags}>
      <SearchAutocompletesList
        control={control}
        labelPlacement="top"
        className={styles.addTagsContainer}
        type="create"
        withBPM={false}
      />
    </div>
  );
};

export default AddTags;
