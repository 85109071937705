import { IAutocompleteOption, IWriterItem } from 'types';

export enum SearchDataKeys {
  searchQuery = 'searchQuery',
  genre = 'genre',
  mood = 'mood',
  vocal = 'vocal',
  language = 'language',
  artists = 'artists',
  bpm = 'bpm',
  audioInclusionType = 'audioInclusionType',
  onHold = 'onHold',
  offHold = 'offHold',
  cut = 'cut',
  unreleasedCut = 'unreleasedCut',
  searchType = 'searchType',
}

export enum SongsSearchTypes {
  viewAllSongsWriters = 'VIEW_ALL_SONGS_WRITERS',
  viewAllSongs = 'VIEW_ALL_SONGS',
  songSearch = 'SONG_SEARCH',
  viewAllSongsAndWriters = 'VIEW_ALL_SONGS_AND_WRITERS',
}

export enum SearchItemsNums {
  seven = 7,
  three = 3,
}

export enum AudioInclusionTypes {
  withAudio = 'WITH_AUDIO',
  withoutAudio = 'WITHOUT_AUDIO',
  both = 'BOTH',
}

export type SearchDataType = {
  [K in SearchDataKeys]:
    | string
    | boolean
    | IUISearchData[]
    | IUISearchDataNested
    | IAutocompleteOption[]
    | AudioInclusionTypes
    | number[]
    | number
    | null;
};

export interface IUISearchNestedDataNested {
  id: number;
  title: string;
  typeId?: number;
}

export interface IUISearchDataNested {
  id: number;
  title: string;
  data?: IUISearchNestedDataNested[];
  hideInDashboard?: boolean;
}

export interface IUISearchData {
  id: number;
  title: string;
  name?: string;
  data?: IUISearchDataNested[];
  value?: SearchDataKeys;
}

export interface IQuickSearchSongTitles {
  id: string;
  workType: string;
  songTitle: string;
  writers: IWriterItem[];
}

export interface IBackendQuickSearchData {
  songTitles: IQuickSearchSongTitles[];
  songWriter: string[];
}

export const defaultBpmData = [0, 220];

export const defaultSearchData: SearchDataType = {
  [SearchDataKeys.searchQuery]: '',
  [SearchDataKeys.genre]: null,
  [SearchDataKeys.mood]: [],
  [SearchDataKeys.vocal]: [],
  [SearchDataKeys.language]: [],
  [SearchDataKeys.artists]: [],
  [SearchDataKeys.bpm]: defaultBpmData,
  [SearchDataKeys.cut]: false,
  [SearchDataKeys.offHold]: false,
  [SearchDataKeys.onHold]: false,
  [SearchDataKeys.unreleasedCut]: false,
  [SearchDataKeys.audioInclusionType]: AudioInclusionTypes.withAudio,
  [SearchDataKeys.searchType]: SongsSearchTypes.songSearch,
};

export const switchButtons: IUISearchData[] = [
  { id: 1, title: 'On Hold', value: SearchDataKeys.onHold },
  { id: 2, title: 'Off Hold', value: SearchDataKeys.offHold },
  { id: 3, title: 'Cut', value: SearchDataKeys.cut },
  { id: 4, title: 'Unreleased Cut', value: SearchDataKeys.unreleasedCut },
];

export const toggleButtons = [
  { title: 'With Audio', value: AudioInclusionTypes.withAudio },
  { title: 'Without Audio', value: AudioInclusionTypes.withoutAudio },
  { title: 'Both', value: AudioInclusionTypes.both },
];

export const songCategoriesTitles: { [key: string]: string } = {
  [SearchDataKeys.genre]: 'Genre',
  [SearchDataKeys.language]: 'Language',
  [SearchDataKeys.mood]: 'Mood',
  [SearchDataKeys.vocal]: 'Vocal',
  [SearchDataKeys.bpm]: 'BPM',
  [SearchDataKeys.artists]: 'Artists',
};

export const songCategories: IUISearchData[] = [
  {
    id: 1,
    title: songCategoriesTitles[SearchDataKeys.genre],
    value: SearchDataKeys.genre,
    data: [],
  },
  {
    id: 2,
    title: songCategoriesTitles[SearchDataKeys.mood],
    value: SearchDataKeys.mood,
    data: [],
  },
  {
    id: 3,
    title: songCategoriesTitles[SearchDataKeys.vocal],
    value: SearchDataKeys.vocal,
    data: [],
  },
  {
    id: 4,
    title: songCategoriesTitles[SearchDataKeys.language],
    value: SearchDataKeys.language,
    data: [],
  },
];
