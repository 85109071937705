import { defaultHoldRequestTabValues } from 'components/AdminControls/data';
import { DownloadStatuses } from 'components/Popups/DownloadPopup/data';
import { addPopupConfigs } from 'components/SongDetails/addPopupConfigs';
import { filterByPlaylist, sortByPlaylist } from 'containers/Sidebar/data';

import { IError, IHoldRequest, IShareConfig, UsageKeys } from 'store/reducers/general/types';

import { additionalRoutes, Paths, routes } from './configs';
import { AddPopupTypes } from 'types';

export const HEADER_TITLE = 'ARROW';
export const MY_FAVORITE_SONGS = 'My Favorite Songs';

// One Trust
export const oneTrustDomainId = process.env.REACT_APP_ONETRUST_DOMAINID || '';
export const cookiesPolicyUrl = process.env.REACT_APP_ONETRUST_POLICYURL || '';
export const oneTrustStyleUrl = process.env.REACT_APP_ONETRUST_STYLESURL || '';

// Adobe Launch
export const adobeLaunchUrl = process.env.REACT_APP_ADOBELAUNCH_URL || '';

// route ids
export const DEFAULT_ACTIVE_NAV_ITEM_ID = 0;
export const SEARCH_PAGE_ROUTE_ID = routes.find((item) => item.path === Paths.search)?.id;
export const SEARCH_ID_PAGE_ROUTE_ID = routes.find((item) => item.path === Paths.searchId)?.id;
export const NOTIFICATIONS_PAGE_ROUTE_ID = additionalRoutes.find((item) => item.path === Paths.notifications)?.id;

// Render different
export const universalPaths: Paths[] = [Paths.support];

export const TABLE_PAGE_SIZE = 50;

export const defaultConfigLoadItems = { startIndex: 0, stopIndex: 0 };

export const MARKER_WIDTH = 6;
export const MARKERS_LIMIT = 10;

export const SONG_TITLE_MAX_LENGTH = 50;

export const NOTIFICATIONS_PAGE_SIZE = 100;
export const NOTIFICATIONS_PAGE_SIZE_IN_POPUP = 10;
export const NOTIFICATION_BADGE_UPDATE_INTERVAL_MINUTES = 5;
export const NOTIFICATION_BADGE_UPDATE_INTERVAL = NOTIFICATION_BADGE_UPDATE_INTERVAL_MINUTES * 60 * 1000; // 5 minutes
export const MAX_NOTIFICATIONS_REQUEST_COUNT = (60 / NOTIFICATION_BADGE_UPDATE_INTERVAL_MINUTES) * 8; // 8 hours
export const MAX_NOTIFICATIONS_COUNT_WARNING =
  MAX_NOTIFICATIONS_REQUEST_COUNT - 10 / NOTIFICATION_BADGE_UPDATE_INTERVAL_MINUTES; // 10 minutes before 8 hours\

// local storage keys
export const IS_OPEN_SIDEBAR = 'IS_OPEN_SIDEBAR';
export const IS_OKTA_LOGIN = 'IS_OKTA_LOGIN';

// ids
export const ADMIN_EDIT_POPUP_ID = 'admin-edit-popup';
export const ADMIN_PAGE_CONTAINER_ID = 'admin-page-container';
export const EDIT_TOOLTIP_CONTAINER_ID = 'edit-tooltip';
export const PLAYLISTS_LIST_CONTAINER_ID = 'virtualized-list';
export const TABLET_PLAYLISTS_LIST_CONTAINER_ID = 'virtualized-list-tablet';
export const PLAYBAR_AUDIO_ELEMENT_ID = 'playbar-audio';

export const SCREEN_BREAKPOINTS = {
  desktopBig: 1380,
  desktopSmall: 1024,
  tablet: 768,
  mobile: 480,
};

export const DEFAULT_USER = {
  user: {
    createdOn: '',
    defaultTeam: null,
    defaultTerritory: null,
    defaultTeamName: null,
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    role: '',
    isTeamAdmin: false,
    status: '',
    updatedBy: '',
    updatedOn: '',
    isReadOnly: false,
  },
  teams: [],
};

export const INITIAL_DOWNLOAD_POPUP_CONFIG = {
  open: false,
  title: '',
  statusText: '',
  status: DownloadStatuses.empty,
  currentProcessText: '',
};

export const INITIAL_ERROR_POPUP_CONFIG = {
  title: '',
  text: '',
  isOpen: false,
};

export const DEFAULT_ERROR_CONFIG: IError = {
  title: 'Oops!',
  text: 'Something went wrong, please try later',
  isOpen: true,
};

export const ARCHIVE_ERROR_CONFIG: IError = {
  title: 'Unable to Archive',
  text: 'Only songs that belong to your default team can be archived by you.',
  isOpen: true,
};

export const DEFAULT_SHARE_CONFIG: IShareConfig = {
  type: '',
  songId: '',
  playlistId: '',
  isOpen: false,
};

export const DEFAULT_HOLD_REQUEST_CONFIG: IHoldRequest = {
  form: {
    isOpen: false,
    workId: '',
  },
  action: {
    isOpen: false,
    holdId: '',
    type: null,
  },
  reject: {
    isOpen: false,
    holdId: '',
  },
  activeTab: null,
  statusTabValues: defaultHoldRequestTabValues,
};

export const DEFAULT_MAIN_CONFIRM_POPUP = {
  isOpen: false,
  questionText: '',
  mainText: '',
};

export const DEFAULT_SONG_DETAILS_DELETE_POPUP_CONFIG = {
  id: '',
  isOpen: false,
  questionText: '',
  mainText: '',
  notificationText: '',
  type: '',
};

export const DEFAULT_SONG_DETAILS_ADD_POPUP_CONFIG = {
  ...addPopupConfigs[AddPopupTypes.status],
  isOpen: false,
};

export const DEFAULT_PITCH_DETAILS_DELETE_POPUP_CONFIG = {
  id: '',
  isOpen: false,
  title: '',
  rowId: '',
};

export const DEFAULT_NOTIFICATIONS_CONFIG = {
  count: 0,
};

export const DEFAULT_PLAYLIST_FILTER = {
  sort: sortByPlaylist[0],
  filterField: filterByPlaylist[1],
  query: '',
};

export const defaultSongsModuleMyFilterData = {
  filterType: '',
  userId: '',
  teamIds: [],
};

export const defaultPitchesConfirmPopupConfig = {
  type: '',
  questionText: '',
  id: '',
  rowId: '',
};

export const defaultPitchesExpirationConfig = {
  isOpenExpirationPopup: false,
  expirationDate: '',
  id: '',
};

export const dateFormat = 'MM-DD-YYYY';
export const dateFormatWithSlash = 'MM/DD/YYYY';

export const ADMIN_USER_ROLES = ['SUPER_USER', 'ARROW_SUPER_ADMIN'];

export const EMAIL_REGEX =
  // eslint-disable-next-line
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;

export const DEFAULT_RECENT_EDITED_SONGS_USAGES = Object.values(UsageKeys);

export const USER_NOT_AUTHORIZED_ERROR_MESSAGE = 'User is not authorized to access this resource with an explicit deny';

export const COPYRIGHT_YEAR = 2024;

export const DEFAULT_WORK_TAGS = {
  genre: [],
  language: [],
  mood: [],
  vocal: [],
  shortcut: [],
};
