import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IConfirmPopupProps } from 'components/Popups/ConfirmPopup/ConfirmPopup';
import { SearchDataType, SearchItemsNums } from 'components/Popups/DesktopSearch/data';
import { ISongsModuleNestedData, songStatuses, SongSyncedBooleans } from 'components/Songs/data';
import { UserStatuses } from 'components/UI/StatusCard/data';
import { IFavoritesData, IPlaylistMedia } from 'containers/Sidebar/data';

import LocalStorage from 'services/LocalStorage';
import {
  IActiveTrack,
  IAdminControls,
  IDownloadPopupConfig,
  IEditPlaylistConfig,
  IError,
  IHoldRequest,
  INotificationsConfig,
  IPitchDetails,
  IPitchesModule,
  IPlaylistFilter,
  IRecentEditedSongs,
  IShareConfig,
  ISongDetails,
  ISongsModule,
  IUserProfile,
  PlayingStateTypes,
  PlaylistHashActionTypes,
} from 'store/reducers/general/types';
import { DEFAULT_RECENT_EDITED_SONGS_USAGES, DEFAULT_WORK_TAGS, getDefaultIsOpenSidebar, IS_OPEN_SIDEBAR } from 'utils';

import generalInitState from './initState';
import { ICurrentTrack, IWorkTags } from 'types';

const generalSlice = createSlice({
  name: 'general',
  initialState: generalInitState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setActiveNavItemId: (state, { payload }: PayloadAction<number>) => {
      state.activeNavItemId = payload;
    },
    toggleIsOpenTopMenu: (state) => {
      state.isOpenTopMenu = !state.isOpenTopMenu;
    },
    toggleIsOpenSearch: (state) => {
      state.isOpenSearch = !state.isOpenSearch;
    },
    setIsOpenSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenSidebar = payload;
      LocalStorage.set(IS_OPEN_SIDEBAR, payload);
    },
    setIsOpenMobileMockup: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenMobileMockup = payload;

      if (state.isOpenSearch) {
        state.isOpenSearch = false;
      }
    },
    setSearchData: (state, { payload }: PayloadAction<Partial<SearchDataType>>) => {
      state.searchData = { ...state.searchData, ...payload };

      const filteredSongStatuses = songStatuses.filter(
        (status) =>
          !!payload[status.searchKey as SongSyncedBooleans] ||
          !!state.searchData[status.searchKey as SongSyncedBooleans]
      );

      state.songsModule.filtersData = {
        ...state.songsModule.filtersData,
        status: filteredSongStatuses,
      };
    },
    setSearchItemsNum: (state, { payload }: PayloadAction<SearchItemsNums>) => {
      state.searchItemsNum = payload;
    },
    setUserProfile: (state, { payload }: PayloadAction<Partial<IUserProfile>>) => {
      state.userProfile = {
        user: payload.user || state.userProfile.user,
        teams: payload.teams || state.userProfile.teams,
      };

      if (payload.user) {
        state.userProfile.user.defaultTeamName = payload.user.defaultTeam?.name || '';
      }

      state.isOpenSidebar =
        !payload?.user?.status || payload?.user?.status !== UserStatuses.ACTIVE ? false : getDefaultIsOpenSidebar();
    },
    // Admin Controls
    setAdminControls: (state, { payload }: PayloadAction<Partial<IAdminControls>>) => {
      state.adminControls = {
        ...state.adminControls,
        ...payload,
        ...(payload.filtersData && {
          filtersData: {
            ...state.adminControls.filtersData,
            ...payload.filtersData,
          },
        }),
        ...(payload.activeEditingUser && {
          activeEditingUser: {
            ...state.adminControls.activeEditingUser,
            ...payload.activeEditingUser,
          },
        }),
      };
    },
    // Songs Module
    setSongsModule: (state, { payload }: PayloadAction<Partial<ISongsModule>>) => {
      state.songsModule = {
        ...state.songsModule,
        ...payload,
        ...(payload.filtersData && {
          filtersData: {
            ...state.songsModule.filtersData,
            ...payload.filtersData,
          },
          myFilter: {
            ...state.songsModule.myFilter,
            ...payload.myFilter,
          },
        }),
      };

      if (payload?.filtersData?.status) {
        const songStatusesObj = {
          onHold: false,
          offHold: false,
          cut: false,
          unreleasedCut: false,
        };

        (payload.filtersData.status as ISongsModuleNestedData[]).forEach((status) => {
          songStatusesObj[status.searchKey as SongSyncedBooleans] = true;
        });

        state.searchData = { ...state.searchData, ...songStatusesObj };
      }
    },
    setSongDetails: (state, { payload }: PayloadAction<Partial<ISongDetails>>) => {
      state.songDetails = {
        ...state.songDetails,
        ...payload,
        ...(payload.addPopupConfig && {
          addPopupConfig: {
            ...state.songDetails.addPopupConfig,
            ...payload.addPopupConfig,
            isEdit: !!payload.addPopupConfig.isEdit,
          },
        }),
        ...(payload.deletePopupConfig && {
          deletePopupConfig: {
            ...state.songDetails.deletePopupConfig,
            ...payload.deletePopupConfig,
          },
        }),
      };
    },

    // Search

    setSelectedRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedRowIndex = payload;
    },

    // recent edited songs
    setRecentEditedSongs: (state, { payload }: PayloadAction<Partial<IRecentEditedSongs>>) => {
      const usages = payload.usages
        ? state.recentEditedSongs.usages.filter((item) => payload.usages?.includes(item))
        : DEFAULT_RECENT_EDITED_SONGS_USAGES;

      state.recentEditedSongs = {
        songs: usages.length ? payload.songs || state.recentEditedSongs.songs : [],
        usages,
      };
    },

    // Pitches
    setPitchesModule: (state, { payload }: PayloadAction<Partial<IPitchesModule>>) => {
      state.pitchesModule = {
        ...state.pitchesModule,
        ...payload,
        ...(payload.confirmPopupConfig && {
          confirmPopupConfig: {
            ...state.pitchesModule.confirmPopupConfig,
            ...payload.confirmPopupConfig,
          },
        }),
        ...(payload.filtersData && {
          filtersData: {
            ...state.pitchesModule.filtersData,
            ...payload.filtersData,
          },
          myFilter: {
            ...state.pitchesModule.myFilter,
            ...payload.myFilter,
          },
        }),
      };
    },
    setPitchDetails: (state, { payload }: PayloadAction<Partial<IPitchDetails>>) => {
      state.pitchDetails = {
        ...state.pitchDetails,
        ...payload,
        ...(payload.deletePopupConfig && {
          deletePopupConfig: {
            ...state.pitchDetails.deletePopupConfig,
            ...payload.deletePopupConfig,
          },
        }),
      };
    },
    // Playlist
    setFavoritesList: (state, { payload }: PayloadAction<IPlaylistMedia[]>) => {
      state.favoritesList = payload;

      state.favoritesIdsObj = Object.assign({}, ...payload.map((k) => ({ [k.id]: true })));
    },
    setFavoritesData: (state, { payload }: PayloadAction<IFavoritesData>) => {
      state.favoritesData = payload;
    },
    setRecentPlayedList: (state, { payload }: PayloadAction<IPlaylistMedia[]>) => {
      state.recentPlayedList = payload;
    },
    setPlaylistFilter: (state, { payload }: PayloadAction<Partial<IPlaylistFilter>>) => {
      state.playlistFilter = {
        ...state.playlistFilter,
        ...(payload.filterField && {
          filterField: payload.filterField,
        }),
        ...(payload.sort && {
          sort: payload.sort,
        }),
        ...(typeof payload.query === 'string' && {
          query: payload.query,
        }),
      };
    },
    updatePlaylistHash: (state, { payload }: PayloadAction<PlaylistHashActionTypes>) => {
      state.playlistHash = {
        action: payload,
        hash: Date.now(),
      };
    },
    updateFeaturedPlaylistHash: (state, { payload: needReset = false }: PayloadAction<boolean | undefined>) => {
      state.featuredPlaylistHash = needReset ? 0 : Date.now();
    },
    setChangedPlaylistId: (state, { payload }: PayloadAction<string>) => {
      state.changedPlaylistId = payload;
    },
    setCurrentPlaylistId: (state, { payload }: PayloadAction<string>) => {
      if (!state.isOpenSidebar) {
        state.isOpenSidebar = true;
      }
      state.currentPlaylistId = payload;
    },
    setEditPlaylist: (state, { payload }: PayloadAction<IEditPlaylistConfig>) => {
      if (!state.isOpenSidebar) {
        state.isOpenSidebar = true;
      }
      state.editPlaylist = payload;
    },
    // Playbar
    setIsExpandedPlaybar: (state, { payload }: PayloadAction<boolean>) => {
      state.isExpandedPlaybar = payload;
    },
    setIsMarkersVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isMarkersVisible = payload;
    },
    setCurrentTracks: (state, { payload }: PayloadAction<ICurrentTrack[]>) => {
      state.currentTracks = payload;
    },
    setActiveTrack: (state, { payload }: PayloadAction<Partial<IActiveTrack>>) => {
      state.activeTrack = { ...state.activeTrack, ...payload };
    },
    setPlaybarPlayingState: (state, { payload }: PayloadAction<PlayingStateTypes>) => {
      state.playbarPlayingState = payload;
    },
    setError: (state, { payload }: PayloadAction<IError>) => {
      state.error = payload;
    },
    setDownloadPopupConfig: (state, { payload }: PayloadAction<IDownloadPopupConfig>) => {
      state.downloadPopupConfig = payload;
    },
    setHoldRequest: (state, { payload }: PayloadAction<Partial<IHoldRequest>>) => {
      state.holdRequest = { ...state.holdRequest, ...payload };
    },
    setShareConfig: (state, { payload }: PayloadAction<Partial<IShareConfig>>) => {
      state.shareConfig = { ...state.shareConfig, ...payload };
    },
    setIsOpenShowHideColumns: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenShowHideColumns = payload;
    },
    setMainConfirmPopup: (state, { payload }: PayloadAction<IConfirmPopupProps>) => {
      state.mainConfirmPopup = payload;
    },
    setMainNotification: (state, { payload }: PayloadAction<string>) => {
      state.mainNotification = payload;
    },
    setNotificationsConfig: (state, { payload }: PayloadAction<INotificationsConfig>) => {
      state.notificationsConfig = payload;
    },
    resetGeneral: () => ({ ...generalInitState }),
    // Tags
    setWorkTags: (state, { payload = DEFAULT_WORK_TAGS }: PayloadAction<IWorkTags | undefined>) => {
      state.workTags = payload;
    },
  },
});

export default generalSlice;
